import React, { useState, useEffect } from 'react';
import { MakeAxiosRequest } from '../../utils/handler';
import { fetchNotificationCount } from '../../features/authSlice';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import WebViewModal from '../../Components/details/WebViewModal';

function Notifications({ notifications, count }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [viewUrl, setViewUrl] = useState('');
  const markAsRead  = async (notification) => {
    try {
        const response = await MakeAxiosRequest("post","/readnotification",{notification_uuid:notification.notificationkey});
        // console.log(response)
        if (response.status == 1) {
          dispatch(fetchNotificationCount());
        switch (notification.type) {
            case 'chat':
                navigate('servicerequest/'+notification.servicekey+'/support')
                break;
            case 'register':
              navigate('/membership')
              break;
            case 'wallet_failed':
            case 'wallet_success':
                navigate('/wallet')
                break;

            case 'renewal_failed':
            case 'renewal_success':
                  navigate('/membership')
                  break;
            case 'proxy_added':
            case 'proxy_wallet_permission':
                  navigate('/relationship')
                  break;
                  
            default:
                notification.rediretUrl && viewDetailsPage(notification.rediretUrl);
                break;
            }
        } else {
            console.log(response);
        }
    } catch (error) {
        console.error("Error", error);
    }
  }
  const clearNotifications = async () => {
    try {
        const response = await MakeAxiosRequest("post", "/clearallnotification");
        // console.log(response)
        if (response.status == 1) {
          dispatch(fetchNotificationCount());
        } else {
            console.log(response);
        }
    } catch (error) {
        console.error("Error", error);
    }
  }
  const viewDetailsPage = (url)=>{
    setViewUrl(url);
    setModalShow(true);
   }
   const handleClose = () => {
    setModalShow(false);
    setViewUrl('');
   };
  return (
    <>
      {notifications.length > 0 ? (
        <React.Fragment>
          <div className="d-flex align-items-center justify-content-between py-2 px-2 mx-2 mb-2 border-primary border-bottom">
            <h4 className="text-center">Notifications</h4>
            <a href="javacsript:void(0)" onClick={clearNotifications} className="link">Clear all</a>
          </div>
           
            {notifications.slice(0, 3).map((notification, optionIndex) => (
                <li>
                  <a className="dropdown-item">
                    <div className="d-flex">
                      <div className="unread-notfctn me-2 mt-1"></div>
                      <div className={`nav-notfctn ${notification.type==='proxy_removed' || 'pointer'}`} key={optionIndex} onClick={() => markAsRead(notification)}>
                        <h5>{notification.title}</h5>
                        <p>{notification.description}</p>
                        <small className="ntfcn-date d-block">{notification.date}</small>
                      </div>
                    </div>
                  </a>
                </li>
              ))
            
          }
        </React.Fragment>

      ) :
      <p className='no-notf-records'>No notifications to show</p>}

      {count > 2 &&
        <div className="text-center">
          <Link className="link small" to={"/notifications"}>View all notification</Link>
        </div>
      }
        {modalShow && <WebViewModal
                    showModal={modalShow}
                    closeModal={handleClose}
                    url={viewUrl}
                />}
    </>
  )
}

export default Notifications