import React, { useEffect, useState } from "react";
import ModalBox from "../../Components/modals/ModalBox";
import { MakeAxiosRequest } from "../../utils/handler";
import { useDispatch, useSelector } from "react-redux";
import { switchProfile } from "../../features/authSlice";

function SelectUserModal({ showModal, closeModal }) {

  const dispatch = useDispatch();
  const userProxyKey = useSelector((state) => state.auth.proxyKey);
  const [userlist, setUserlist] = useState();
  const [selectedUser, setSelectedUser] = useState(userProxyKey);

  useEffect(() => {
    fetchData();
  }, [showModal]);

  const fetchData = async () => {
    try {
      const response = await MakeAxiosRequest("post", "/userlist",{user_proxy_key: userProxyKey});
      if (response.status == 1) {
        setUserlist(response.data.userlist);
      } else {
        console.log(response.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleUserSelect = (key) => {
    dispatch(switchProfile(key));
    setSelectedUser(key);
    closeModal();
  };

  return (
    <ModalBox
      isShow={showModal}
      handleClose={closeModal}
      modalSize="md select-user"
      hasData={userlist}
    >
      <div className="text-center mb-4">
        <h4>Select User</h4>
      </div>
      {userlist &&
      <div className="select-user">
      {userlist[0] && (
        <div className="myself-sec mb-4" onClick={()=>handleUserSelect('')}>
          <div className="rdio rdio-primary radio-inline">
          <input
                  type="radio"
                  name="proxy"
                  value={userlist[0]?.users[0]?.key}
                  checked={selectedUser===userlist[0]?.users[0]?.key || userlist[0]?.users[0]?.is_selected}
                  // onChange={handleUserSelect}
                />
                 <label htmlFor={`plan-`}></label>
                 
          </div>
          <div className="d-flex align-items-center mb-2 ms-1">
                    <img src={userlist[0]?.users[0]?.image} className="prfl-sec"></img>
                    <p className="mb-0">{userlist[0]?.users[0]?.name}</p>
                    <span className="span-tag">{userlist[0]?.users[0]?.profile_label}</span>
                   </div>
        </div>)}
        {userlist[1] && (
          <>
            <h6 className="mb-3">{userlist[1].title}</h6>
            
            {userlist[1].users.map((usr) => (
              <>
             
                <div className="rdio rdio-primary radio-inline selection" onClick={()=>handleUserSelect(usr.key)}>
                <div className={`membrshp-bill ${(selectedUser===usr.key || usr.is_selected) && 'select-rlshp'} mb-2`} >
                  <input
                    type="radio"
                    name="proxy"
                    value={usr.key}
                    checked={selectedUser===usr.key || usr.is_selected}
                    id={`plan-`}
                  />
                  <label htmlFor={`plan-`}></label>
                  <div className="rlshp-img ms-2">
                  <img src={usr.image} className="prfl-sec"></img>
                  <span><i class="fa-solid fa-check"></i></span>
                  </div>
                 
                <div>
                  <p className="mb-0">{usr.name}</p>
                  <small className="white d-block">{usr.relationship}</small>
                </div>
                </div>
                </div>

              {/* <div className="membrshp-bill mb-2" onClick={()=>handleUserSelect(usr.key)}>
                <input
                  type="radio"
                  name="proxy"
                  value={usr.key}
                  checked={selectedUser===usr.key || usr.is_selected}
                  // onChange={handleUserSelect}
                />

                <div className="rlshp-img">
                  <img src={usr.image} className="prfl-sec"></img>
                </div>
                <div>
                  <p className="mb-0">{usr.name}</p>
                  <small className="white d-block">{usr.relationship}</small>
                </div>
              </div> */}
              </>
            ))}
          </>
        )}
      </div>}
    </ModalBox>
  );
}

export default SelectUserModal;
