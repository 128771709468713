import React, { useEffect, useState } from 'react'
import ModalBox from '../modals/ModalBox';
import Loader from '../loader/Loader';
import { MakeAxiosRequest } from '../../utils/handler';
import { SwalError } from '../SwalFns';

function WebViewModal({ showModal, closeModal, url, title, path }) {
    const [isLoading, setIsLoading] = useState(true);
    const [response, setResponse] = useState('');

    const fetchTokenUrl = async () => { 
        
        try {
           const res = await MakeAxiosRequest('post',"getwebtokenurl",{path});
           if(res.status==1){
            setResponse(res.data);
            // setIsLoading(false);
           }else{
            closeModal();           
            SwalError({ msg: res.message });
           }
        } catch (error) {
           console.error('Error fetching data:', error);
          //  closeModal();           
          //  setLoadingStatus({ page:false,table: false });
        }
     };
     
     useEffect(() => {
       if(!url && path){
        fetchTokenUrl();
       }
     }, [])
     
  return (
    <ModalBox
      isShow={showModal}
      handleClose={closeModal}
      modalSize="lg"
      hasData={true}
    >
      {isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Loader/>
        </div>
      )}
    
    {title && !isLoading && <div className="text-center">
    <h3 className="modal-title mb-3">
      {title}
      </h3>
      </div>}
      <div style={{ width: '100%', height: '100vh', display: isLoading ? 'none' : 'block' }}>
        <iframe
          src={url || response.url}
          style={{ width: '100%', height: '100%', border: 'none' }}
          onLoad={()=>setIsLoading(false)}
        />
    </div>
    </ModalBox>
  )
}

export default WebViewModal