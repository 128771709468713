import React from "react";
import { Modal, CloseButton } from "react-bootstrap";
import Loader from "../loader/Loader";

function ModalBox(props) {

    return (
    <Modal
      show={props.isShow}
      // onHide={props.handleClose}
      dialogClassName={`modal-dialog modal-dialog-centered modal-${props.modalSize ? props.modalSize : 'lg' }`}
    >
      {/* <Modal.Header className="border-0 pt-4"> */}
        {!!props.hideClose || <CloseButton
          onClick={props.handleClose}
        >
          <i className="fa-solid fa-xmark"></i>
          </CloseButton>}
      {/* </Modal.Header> */}
      <Modal.Body>
        {!props.hasData ? (
          <Loader />
        ) : (
          props.children
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ModalBox;
