import React from "react";
import { useFormContext, Controller } from "react-hook-form";

function AgreeBtnField({ name, label, rules, text, className }) {
  const {
    control,
    setValue,
    formState: { errors },
    getValues,
  } = useFormContext();

  return (
    <div className={className}>
      <Controller
        name={name}
        control={control}
        defaultValue={false} // Set the default value to false for toggling
        rules={rules}
        render={({ field }) => (
          <div className="enable-payment">
          <div className="form-check form-checked" >
            <input
              {...field} // Spread field props to bind value and onChange
              type="checkbox"
              checked={getValues(name)=='1'}
              className="form-check-input"
              value="1"
              id={`${name}-button`}
            />
            <label htmlFor={`${name}-button`} className="form-label mb-2 ms-1">
              {label}
            </label>
            <small className="note d-block ms-1 test" htmlFor={`${name}-button`}>
              {text}
            </small>
          </div>
          </div>
        )}
      />
      {errors[name] && <span className="error">{errors[name].message}</span>}
    </div>
  );
}

export default AgreeBtnField;
